import React from 'react'
import Layout from '../components/layout'
import { makeStyles } from '@material-ui/core/styles'
import { Container, Grid } from '@material-ui/core'
import { EmailOutlined, PhoneOutlined } from '@material-ui/icons'
import ContactForm from '../components/ContactForm'
import {
  dirtyWhite,
  militaryGreen,
  lightGreen,
  darkGreen,
  slateGrey,
} from '../constants/colors'
import Image from '../components/image'
import SEO from '../components/seo'
import { phone, email } from '../constants/companyInfo'
import backgroundImg from '../images/e.jpg'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: 'white',
    padding: '10px',
    marginBottom: `60px`,
  },
  title: {
    fontSize: 48,
    padding: '10px',
    margin: '50px 0px',
    fontWeight: 300,
    width: '100%',
    textAlign: 'center',
  },
  subtitle: {
    fontSize: 28,
    margin: '50px 0px',
    fontWeight: 300,
    width: '100%',
    textAlign: 'center',
  },
  contactUsContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  contactUsSpan: {
    color: slateGrey,
    borderBottom: `1px solid ${slateGrey}`,
    fontWeight: 400,
    marginRight: '1rem',
  },
  contactInfoContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 5,
    marginBottom: 15,
  },
  brandContainer: {
    width: '100%',
    padding: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  contactIconSpan: {
    color: darkGreen,
    marginTop: 15,
    marginBottom: 15,
    fontSize: 22,
  },
}))
const contactText = `When you're in need of a dependable Landscaping Service don’t hesitate. Contact 4NB Design & Build today.`
const Gallery = () => {
  const classes = useStyles()

  return (
    <Layout backImg={backgroundImg} headerTitle="LET'S GET IN TOUCH">
      <SEO title="Contact" />
      <Container className={classes.root}>
        <Grid container justify="center">
          <Grid item xs={12} className={classes.contactUsContainer}>
            <h1 className={classes.title}>
              <span className={classes.contactUsSpan}>Contact Us Now</span>
              and get your free quote
            </h1>
          </Grid>
        </Grid>
        <Grid container justify="space-evenly">
          <Grid item md={4} className={classes.contactInfoContainer}>
            <div className={classes.brandContainer}>
              <div style={{ width: '80%', marginTop: 15 }}>
                <Image />
              </div>
            </div>
            <h2 className={classes.subtitle}>{contactText}</h2>
            <span className={classes.contactIconSpan}>
              <PhoneOutlined />
            </span>
            <p style={{ color: slateGrey, fontSize: 22 }}>{phone}</p>
            <span className={classes.contactIconSpan}>
              <EmailOutlined />
            </span>
            <p style={{ color: slateGrey, fontSize: 22 }}>{email}</p>
          </Grid>
          <Grid item xs={12} md={6}>
            <ContactForm />
          </Grid>
        </Grid>
      </Container>
    </Layout>
  )
}

export default Gallery
