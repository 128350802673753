import React, { useState, useEffect } from 'react'
import './testimonials.css'
import FormatQuoteIcon from '@material-ui/icons/FormatQuote'
import { darkGreen } from '../constants/colors'

const Testimonials = ({ testimonialData }) => {
  const [slider, setSlider] = useState('slide0')
  useEffect(() => {
    const timer = setInterval(() => {
      autoSliderHandler()
    }, 8000)
    return () => clearInterval(timer)
  }, [slider])
  // console.log(data)

  const sliderHandler = e => {
    setSlider(e.target.value)
  }
  const autoSliderHandler = () => {
    const sliders = ['slide0', 'slide1', 'slide2'] //Important naming convention
    if (slider === sliders[sliders.length - 1]) {
      setSlider(sliders[0])
      return
    }
    sliders.forEach((slide, index) => {
      if (slide === slider) {
        setSlider(sliders[index + 1])
        return
      }
    })
  }

  return (
    <div onClick={autoSliderHandler} className="slider">
      {testimonialData.map((el, i) => {
        return (
          <input
            key={i}
            type="radio"
            name="slider"
            value={`slide${i}`}
            onChange={sliderHandler}
            checked={slider === `slide${i}`}
            className="slider__nav"
          />
        )
      })}
      <div className="slider__inner">
        {testimonialData.map(testimonial => {
          return (
            <div key={testimonial.name} className="slider__contents">
              <FormatQuoteIcon fontSize="large" style={{ color: darkGreen }} />
              <h2 className="slider__caption">{testimonial.name}</h2>
              <p className="slider__txt">{testimonial.body}</p>
            </div>
          )
        })}
      </div>
    </div>
  )
}
export default Testimonials
