import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Typography from '@material-ui/core/Typography'
import { slateGrey } from '../constants/colors'

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    width: '95%',
    borderRadius: 0,
  },
  media: {
    height: 230,
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
})

export default function TeamCard(props) {
  const classes = useStyles()
  return (
    <Card className={classes.root}>
      <CardMedia className={classes.media} image={props.img} title="Face" />
      <CardContent className={classes.cardContent}>
        <Typography
          gutterBottom
          variant="h5"
          component="h2"
          style={{ color: slateGrey, fontWeight: 300 }}
        >
          {props.name}
        </Typography>
      </CardContent>
    </Card>
  )
}
