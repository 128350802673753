import React, { useState, useRef } from 'react'
import emailjs from 'emailjs-com'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/styles'
import { darkGreen, slateGrey, lightGreen } from '../constants/colors'

const useStyles = makeStyles(theme => ({
  msg: {
    border: `2px solid ${darkGreen}`,
    marginTop: '1rem',
  },
  input: {
    borderBottom: `2px solid ${darkGreen}`,
    '& label.Mui-focused': {
      color: darkGreen,
    },
  },
  form: {
    backgroundColor: '#f1f1f1',
    display: 'flex',
  },
  submit: {
    width: '10rem',
    alignSelf: 'center',
    color: slateGrey,
    border: `2px solid transparent`,
    borderRadius: 0,
    '&:hover': {
      border: `2px solid ${slateGrey}`,
    },
    '&:focus': {
      border: `2px solid ${slateGrey}`,
    },
  },
  mb1: {
    marginBottom: '1rem',
  },
}))

const ContactForm = props => {
  const classes = useStyles()
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [message, setMessage] = useState('')
  const [err, setErr] = useState('')
  const [success, setSuccess] = useState(false)

  const nameRef = useRef(null)
  const emailRef = useRef()
  const phoneRef = useRef()
  const msgRef = useRef()
  const btnRef = useRef()

  const clearForm = () => {
    setName('')
    setEmail('')
    setPhone('')
    setMessage('')
  }

  const submission = e => {
    e.preventDefault()
    if (email === '' && phone === '') {
      setErr('Please, enter your e-mail or phone')
      return
    } else {
      setErr('')
      const emailParams = {
        from_name: name,
        message: message,
        from_phone: phone ? phone : 'N/A',
        from_email: email ? email : 'N/A',
        reply_to: email ? email : 'N/A',
      }
      emailjs
        .send(
          process.env.GATSBY_EMAIL_SERVICE_ID,
          process.env.GATSBY_EMAIL_TEMPLATE_ID,
          emailParams,
          process.env.GATSBY_EMAIL_USER_ID
        )
        .then(res => {
          setSuccess(true)
          clearForm()
        })
        .catch(err => {
          setErr(
            'Unable to send message at this moment. Please try again or contact us directly.'
          )
          clearForm()
        })
    }
  }
  const handleChangeName = e => {
    success && setSuccess(false)
    setName(e.target.value)
  }
  const handleChangePhone = e => {
    success && setSuccess(false)
    setPhone(e.target.value)
  }

  const handleChangeEmail = e => {
    success && setSuccess(false)
    setEmail(e.target.value)
  }

  const handleChangeMessage = e => {
    success && setSuccess(false)
    setMessage(e.target.value)
  }

  return (
    <Grid
      item
      container
      direction="column"
      className={classes.form}
      style={{ padding: '5rem' }}
    >
      <div style={{ position: 'relative' }}>
        {success ? (
          <p style={{ color: lightGreen, textAlign: 'center' }}>
            Thank you! We will contact you shortly
          </p>
        ) : null}
        {err ? (
          <p style={{ color: '#c62828', textAlign: 'center' }}>{err}</p>
        ) : null}
      </div>

      <Grid item className={classes.mb1}>
        <TextField
          className={classes.input}
          InputProps={{ disableUnderline: true }}
          type="text"
          label="Name"
          id="name"
          fullWidth
          value={name}
          onChange={handleChangeName}
          onKeyPress={event => {
            if (event.key.toString() === 'Enter') {
              emailRef.current.focus()
            }
          }}
        />
      </Grid>
      <Grid item className={classes.mb1}>
        <TextField
          inputRef={emailRef}
          className={classes.input}
          InputProps={{ disableUnderline: true }}
          type="email"
          label="Email"
          id="email"
          fullWidth
          value={email}
          onChange={handleChangeEmail}
          onKeyPress={e => {
            if (e.key === 'Enter') {
              phoneRef.current.focus()
            }
          }}
        />
      </Grid>
      <Grid item className={classes.mb1}>
        <TextField
          inputRef={phoneRef}
          className={classes.input}
          InputProps={{ disableUnderline: true }}
          type="tel"
          label="Phone"
          id="phone"
          fullWidth
          value={phone}
          onChange={handleChangePhone}
          onKeyPress={e => {
            if (e.key === 'Enter') {
              msgRef.current.focus()
            }
          }}
        />
      </Grid>
      <Grid item className={classes.mb1}>
        <TextField
          inputRef={msgRef}
          InputProps={{ disableUnderline: true }}
          className={classes.msg}
          multiline
          rows={7}
          id="message"
          fullWidth
          value={message}
          onChange={handleChangeMessage}
          onKeyPress={e => {
            if (e.key === 'Enter') {
              btnRef.current.focus()
            }
          }}
        />
      </Grid>
      <Button
        ref={btnRef}
        className={classes.submit}
        onClick={submission}
        onKeyPress={e => {
          if (e.key === 'Enter') {
            submission()
          }
        }}
        size="large"
        color="primary"
      >
        Send Message
      </Button>
    </Grid>
  )
}

export default ContactForm
