import React from 'react'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  close: {
    position: 'absolute',
    top: 0,
    right: 2,
    zIndex: 100,
    background: 'rgba(0,0,0,0.1)',
    '&:hover': {
      background: 'rgba(0,0,0,0.2)',
    },
  },
}))

export default function Modal({ img, closeModal }) {
  const classes = useStyles()
  return (
    <div style={{ overflow: 'hidden' }}>
      <IconButton onClick={() => closeModal()} className={classes.close}>
        <CloseIcon style={{ color: 'white' }} />
      </IconButton>
      <img src={img} style={{ objectFit: 'contain' }} />
    </div>
  )
}
