import React from 'react'
import { graphql, useStaticQuery, navigate } from 'gatsby'

import { useStyles } from '../styles/pages/index'
import Layout from '../components/layout'
import SEO from '../components/seo'
import {
  Grid,
  Container,
  GridList,
  GridListTile,
  colors,
} from '@material-ui/core'
import { Check } from '@material-ui/icons'
// import { tileData } from '../constants/images'
import ButtonHorizontal from '../components/buttonHorizontal'
import ButtonVertical from '../components/buttonVertical'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Testimonials from '../components/testimonials'
import backgroundImg from '../images/ab.jpg'
import { citiesServiced } from '../constants/companyInfo'

import Process from '../components/process'

const servicesOffered = ['WALKWAYS', 'PATIOS', 'DRIVEWAYS', 'POOL DECKS']
const descriptionText =
  "4NB Design and Build is a landscape construction company that takes pride in creating both aesthetically pleasing and functional spaces for you to enjoy. We implement sustainable landscaping practices and use quality products to get the results you desire. The guiding principle of 4NB is to turn each of our customer's visions into reality."

const IndexPage = props => {
  const { allContentfulHomePage: homeData } = useStaticQuery(graphql`
    query HomePageImages {
      allContentfulHomePage {
        nodes {
          displayImages {
            file {
              url
            }
          }
          testimonials {
            data {
              body
              name
            }
          }
        }
      }
    }
  `)

  const tileData = homeData.nodes[0].displayImages
  const testimonialData = homeData.nodes[0].testimonials
  console.log(testimonialData)
  const classes = useStyles()
  const small = useMediaQuery('(max-width:750px)')

  return (
    <Layout
      backImg={backgroundImg}
      headerTitle="TURNING YOUR VISION INTO REALITY"
    >
      <SEO title="Home" />
      <Container className={classes.textContainer}>
        <div style={{ textAlign: 'center', width: '100%' }}>
          <h1 className={classes.title}>
            Your <span className={classes.titleHighlight}>Interlocking</span>{' '}
            and <span className={classes.titleHighlight}>Natural Stone</span>{' '}
            Contractor
          </h1>
        </div>
      </Container>
      <Container className={classes.tilesContainer}>
        <Grid
          container
          justify="space-between"
          style={{
            padding: '0px',
            background: 'rgba(241,241,241,0.9)',
          }}
        >
          <Grid
            item
            md={7}
            className={classes.colCenter}
            style={{
              background: 'rgba(91, 123, 122, 0.9)',
              height: '100%',
              width: '100%',
            }}
          >
            <div
              className={`${classes.colCenter}`}
              style={{ width: '100%', height: '100%' }}
            >
              <p className={classes.description}>{descriptionText}</p>
            </div>
          </Grid>
          <Grid
            item
            md={4}
            className={classes.colStartCenter}
            style={{ paddingTop: '2rem' }}
          >
            {servicesOffered.map(service => (
              <h1 className={classes.service}>
                {' '}
                <Check
                  style={
                    small
                      ? { fontSize: 26, margin: '0px 20px 0px 20px' }
                      : { fontSize: 32, margin: '0px 20px 0px 20px' }
                  }
                />
                {service}
              </h1>
            ))}
          </Grid>
        </Grid>
      </Container>
      <Container
        style={{
          boxShadow: '2px 2px 4px 1px silver',
          padding: 0,
          marginTop: 0,
        }}
      >
        <Grid
          container
          justify="center"
          style={{ background: '#f1f1f1', paddingTop: 20 }}
        >
          <Grid container justify="space-between">
            <Grid item md={6} className={classes.contactUsContainer}>
              <ButtonHorizontal
                title="Contact Us"
                onClick={() => navigate('/contact')}
              />
            </Grid>
            <Grid
              item
              md={6}
              className={`${classes.colCenter}`}
              style={{ paddingTop: '1rem' }}
            >
              <h2 className={classes.citiesTitle}>Proudly Serving</h2>
              <div className={classes.citiesList}>
                {citiesServiced.map(city => {
                  return <h3 className={classes.city}>{city}</h3>
                })}
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Grid container justify="center" className={classes.gridRoot}>
        <Grid container justify="center" alignItems="center">
          <Grid
            item
            md={8}
            sm={10}
            style={{
              width: '100%',
              height: '25rem',
              backgroundColor: '#f1f1f1',
              border: '4px solid white',
              boxShadow: '2px 2px 4px 1px silver',
            }}
          >
            <iframe
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/1msKtaNjhJM"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </Grid>
        </Grid>
      </Grid>
      <Grid container className={classes.gridRoot}>
        <Grid container justify="center">
          <Grid item sm={10} md={8} className={classes.imgGridItem}>
            <GridList
              className={classes.gridList}
              cellHeight={240}
              cols={small ? 1 : 2}
            >
              {tileData.map((tile, index) => (
                <GridListTile key={index} cols={1}>
                  <img
                    src={tile.file.url}
                    alt={`Display image #${index}`}
                    className={classes.img}
                  />
                </GridListTile>
              ))}
            </GridList>
            <ButtonVertical
              style={{ justifySelf: 'center' }}
              title="View Full Gallery"
              onClick={() => navigate('/gallery')}
            />
          </Grid>
        </Grid>
      </Grid>
      <Process />
      <Grid container className={classes.testimonials}>
        <Testimonials testimonialData={testimonialData.data} />
      </Grid>
    </Layout>
  )
}

export default IndexPage
