export const phone = `(416)-897-5032`
export const email = `4nbuild@gmail.com`
export const citiesServiced = [
  'Mississauga',
  'Oakville',
  'Milton',
  'Burlington',
  'Georgetown',
  'Etobicoke',
  'Vaughan',
  'Toronto',
]
