import React from 'react'
import {
  Grid,
  Container,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Hidden,
} from '@material-ui/core'
import { slateGrey } from '../constants/colors'
import { Forward } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import PhaseOne from '../images/phase1.png'
import PhaseTwo from '../images/phase2.png'
import PhaseThree from '../images/phase3.png'
import Model3D from '../images/threeD.png'

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: 48,
    fontWeight: 300,
    fontFamily: 'Oswald',
    padding: 50,
    color: '#2f4f4f',
    borderBottom: `1px solid #cbd3d3`,
  },
  subtitle: {
    fontSize: 38,
    fontFamily: 'Oswald',
    fontWeight: 400,
    padding: 30,
    color: 'whitesmoke',
    cursor: 'default',
    width: '100%',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      padding: 15,
      fontSize: 30,
    },
    [theme.breakpoints.down('sm')]: {
      padding: 5,
      fontSize: 28,
    },
  },
  demo: {
    background: '#2f4f4f',
    height: '100%',
    color: 'white',
  },
  demoEven: {
    background: '#cbd3d3',
    height: '100%',
    color: slateGrey,
  },
  phaseTwoList: {
    order: 2,
    [theme.breakpoints.up('md')]: {
      order: 1,
    },
  },
  phaseTwoTitle: {
    height: 300,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    order: 1,
    [theme.breakpoints.up('md')]: {
      order: 2,
    },
  },
  phaseTitle: {
    color: slateGrey,
    fontSize: 38,
    fontWeight: 300,
    marginLeft: 5,
  },
  phaseTitleSpan: {
    color: 'white',
    padding: 5,
    background: slateGrey,
    fontSize: 38,
  },
  columnCenter: {
    height: 300,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  rowCenter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  threeDImagingContainer: {
    marginTop: 40,
    background: 'slategrey',
    display: 'flex',
    justifyContent: 'center',
    padding: 10,
  },
  theeeDImagingTitleSpan: {
    padding: 10,
    backgroundColor: 'whitesmoke',
    border: '2px solid slategrey',
    color: 'slategrey',
    boxShadow: '2px 2px 2px 0px silver',
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.75,
    },
  },
}))

const processData = {
  step1: [
    'Analyze our clients unique site while taking into consideration the topography and natural elements such as light exposure.',
    'Measurements are taken and relevant data is recorded',
    'A personalized discussion with the client, to identify the best possible approach to acheive their desired goals',
  ],
  step2: [
    'Create customized design options for the client using hand made graphics and/or digital rendering tools',
    'Client provides input to edit/select the ideal design option',
    'Upon successful approval of the design, we introduce material, texture and color options',
  ],
  step3: [
    'Quality excavation of a designated area to a necessary depth',
    'The area is then backfilled with quality material and compacted to achieve a solid base',
    'After final grading is completed we install the desired hardscape products, and conclude with a final inspection',
  ],
}

const Process = () => {
  const classes = useStyles()

  return (
    <Container
      style={{
        marginTop: 40,
        background: '#f1f1f1',
        paddingBottom: 20,
        boxShadow: '2px 2px 2px 1px silver',
      }}
    >
      <Grid container>
        <div style={{ textAlign: 'center', width: '100%' }}>
          <h1 className={classes.title}>HOW WE APPROACH OUR PROJECTS</h1>
        </div>
        <Grid container justify="space-evenly">
          <Grid item sm={12} md={6} className={classes.columnCenter}>
            <div>
              <h1 className={classes.phaseTitle}>
                <span className={classes.phaseTitleSpan}>Phase 1</span>
                Site Analysis
              </h1>
            </div>
            <div>
              <img src={PhaseOne} style={{ width: 100, height: 'auto' }} />
            </div>
          </Grid>
          <Grid item sm={12} md={6}>
            <div className={classes.demo}>
              <List>
                {processData.step2.map(listItem => (
                  <ListItem slyle={{ color: 'white', fontWeight: 400 }}>
                    <ListItemIcon>
                      <Forward
                        style={{ color: 'darkslategrey', background: 'white' }}
                      />
                    </ListItemIcon>
                    <ListItemText primary={listItem} />
                  </ListItem>
                ))}
              </List>
            </div>
          </Grid>
        </Grid>
        <Grid container justify="space-evenly">
          <Grid item sm={12} md={6} className={classes.phaseTwoList}>
            <div className={classes.demoEven}>
              <List>
                {processData.step1.map(listItem => (
                  <ListItem>
                    <ListItemIcon>
                      <Forward
                        style={{ color: 'white', background: slateGrey }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={listItem}
                      slyle={{ color: slateGrey }}
                    />
                  </ListItem>
                ))}
              </List>
            </div>
          </Grid>
          <Grid item sm={12} md={6} className={classes.phaseTwoTitle}>
            <div>
              <h1 className={classes.phaseTitle}>
                <span className={classes.phaseTitleSpan}>Phase 2</span>
                Project Design
              </h1>
            </div>
            <div>
              <img src={PhaseTwo} style={{ width: 100, height: 'auto' }} />
            </div>
          </Grid>
        </Grid>
        <Grid container justify="space-evenly">
          <Grid item sm={12} md={6} className={classes.columnCenter}>
            <div>
              <h1 className={classes.phaseTitle}>
                <span className={classes.phaseTitleSpan}>Phase 3</span>
                Construction
              </h1>
            </div>
            <div>
              <img src={PhaseThree} style={{ width: 100, height: 'auto' }} />
            </div>
          </Grid>
          <Grid item sm={12} md={6}>
            <div className={classes.demo}>
              <List>
                {processData.step3.map(listItem => (
                  <ListItem slyle={{ color: 'white', fontWeight: 400 }}>
                    <ListItemIcon>
                      <Forward
                        style={{ color: 'darkslategrey', background: 'white' }}
                      />
                    </ListItemIcon>
                    <ListItemText primary={listItem} />
                  </ListItem>
                ))}
              </List>
            </div>
          </Grid>
        </Grid>
        <Grid container className={classes.threeDImagingContainer}>
          <Grid item md={7} className={classes.rowCenter}>
            <h2 className={classes.subtitle}>
              ASK US ABOUT{' '}
              <span className={classes.theeeDImagingTitleSpan}>3D IMAGING</span>
            </h2>
          </Grid>
          <Grid item md={5} className={classes.rowCenter}>
            <img
              src={Model3D}
              style={{
                width: '100%',
                height: '100%',
                margin: 0,
                boxShadow: '2px 2px 2px 0px slategrey',
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}

export default Process
