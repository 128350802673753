import { makeStyles } from '@material-ui/core/styles'
import { slateGrey } from '../../constants/colors'
import TdotImg from '../../images/toronto.jpg'
import tilesBg from '../../images/tiles_bg.jpeg'
import brand from '../../images/brand.png'

export const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textContainer: {
    width: '100%',
  },
  title: {
    fontSize: 48,
    padding: '100px 0px',
    // margin: '50px 0px',
    fontWeight: 300,
  },
  serviceContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
    margin: 5,
  },
  service: {
    fontSize: 38,
    fontWeight: 300,
    color: slateGrey,
  },
  imgGrid: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  titleHighlight: {
    color: slateGrey,
    fontWeight: 400,
    borderBottom: `1px solid ${slateGrey}`,
  },

  gridRoot: {
    width: '100%',

    marginTop: 40,
  },
  imgGridItem: {
    width: '100%',
    background: '#f1f1f1',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '2px 2px 4px 1px silver',
  },
  img: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
    border: `6px solid ${slateGrey}`,
  },
  gridList: {
    width: '100%',
    padding: 10,
  },
  overlapContainer: {
    width: '100%',
    minHeight: '40rem',
    position: 'relative',
    overflow: 'hidden',
  },
  box: {
    width: '60%',
    height: '90%',
    position: 'absolute',
    top: 0,
    left: 0,
    opacity: 0.8,
    background: 'red',
  },
  stackTop: {
    zIndex: 9,
    margin: '10% 40%',
    background: 'blue',
    height: '100%',
  },
  testimonials: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'space-between',
    height: '30rem',
    marginTop: '5rem',
    backgroundImage: `url(${TdotImg})`,
    backgroundPosition: '0% 25%',
  },
  citiesList: {
    color: 'white',
    background: '#f1f1f1',
    width: '80%',
    display: 'flex',
    lexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-evenly',
  },
  citiesTitle: {
    backgroundColor: 'slateGrey',
    color: '#FFF',
    boxShadow: '2px 2px 2px 1px darkgrey',
    fontWeight: 300,
    width: '50%',
    textAlign: 'center',
    padding: 3,
  },
  city: {
    fontWeight: 400,
    color: 'slategrey',
    marginLeft: 5,
    marginRight: 5,
  },
  colCenter: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  backroundBrand: {
    backgroundImage: `url(${brand})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '0% -180px',
    width: '100%',
    height: '100%',
  },
  colStartCenter: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  description: {
    width: '100%',
    height: '100%',
    textAlign: 'center',
    fontWeight: 400,
    letterSpacing: 1.7,
    color: 'whitesmoke',
    padding: '4rem 1rem',
  },
  contactUsContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'rgb(250,240,220)',
    background: `linear-gradient(90deg, rgba(250,240,220,1) 0%, rgba(11,65,65,1) 31%)`,
  },

  tilesContainer: {
    padding: 60,
    backgroundPosition: 'center',
    objectFit: 'cover',
    backgroundImage: `url(${tilesBg})`,
  },
}))
