import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Header from './header'
import { Grid } from '@material-ui/core'
import { dirtyWhite } from '../constants/colors'
import Instagram from '../images/instagram.svg'
import './layout.css'

const Layout = ({ children, headerTitle, backImg }) => {
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <div style={{ minHeight: '100vh', backgroundColor: '#FFF' }}>
          <Header
            backImg={backImg}
            headerTitle={headerTitle}
            siteTitle={data.site.siteMetadata.title}
          />

          <div
            style={{
              margin: `0 auto`,
              padding: `0px`,
              paddingTop: 0,
              backgroundColor: dirtyWhite,
            }}
          >
            <main>{children}</main>
            <footer
              style={{
                paddingTop: 10,
                textAlign: 'center',
                color: dirtyWhite,
                background: 'darkslategray',
              }}
            >
              <Grid container justify="space-evenly">
                <Grid item sm={12}>
                  <a href="https://www.instagram.com/4nbuild/">
                    <img
                      src={Instagram}
                      style={{ width: 30, height: 30, margin: 10 }}
                    />
                  </a>
                </Grid>
              </Grid>
              © {new Date().getFullYear()},{` `} 4NB Design & Build Ltd.
            </footer>
          </div>
        </div>
      )}
    />
  )
}

export default Layout
