import React from 'react'
import './button.css'

export default function ButtonVertical(props) {
  return (
    <div
      onClick={props.onClick}
      className="horizontal"
      style={props.style || {}}
    >
      <span className="text">{props.title}</span>
    </div>
  )
}
