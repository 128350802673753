import React, { useState, useEffect } from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import Grid from '@material-ui/core/Grid'
import GridList from '@material-ui/core/GridList'
import GridListTile from '@material-ui/core/GridListTile'
import GridListTileBar from '@material-ui/core/GridListTileBar'
import FormLabel from '@material-ui/core/FormLabel'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import CircularProgress from '@material-ui/core/CircularProgress'
import Checkbox from '@material-ui/core/Checkbox'
import IconButton from '@material-ui/core/IconButton'
import LaunchIcon from '@material-ui/icons/Launch'
import InstaIcon from '@material-ui/icons/Instagram'
import { withStyles } from '@material-ui/core/styles'
import { slateGrey } from '../constants/colors'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import backgroundImg from '../images/r.jpg'
import ModalContent from '../components/modal'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
    padding: 10,
  },
  modal: {
    position: 'absolute',
    maxWidth: '85%',
    maxHeight: '98%',
    margin: 'auto',
    backgroundColor: 'whitesmoke',
    boxShadow: theme.shadows[5],
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    fontSize: 48,
    padding: '10px',
    margin: '50px 0px 10px 0px',
    fontWeight: 300,
    width: '100%',
    textAlign: 'center',
  },
  subtitle: {
    fontSize: '28px',
    padding: '10px',
    margin: '15px 0px 25px 0px',
    fontWeight: 300,
    width: '100%',
    textAlign: 'center',
    color: 'slategrey',
  },
  subtitleLink: {
    color: '#FFF',
    fontWeight: 300,
    padding: '0px 5px',
    background: 'rgb(217,217,217)',
    background:
      'linear-gradient(129deg, rgba(217,217,217,1) 0%, rgba(10,13,15,1) 56%)',
    '&:hover': {
      boxShadow: '0px 1px 3px 0px rgba(10, 13, 15,1)',
      background:
        'linear-gradient(129deg, rgba(217,217,217,1) 0%, rgba(10,13,15,1) 56%)',
    },
  },
  gridList: {
    width: '95%',
    transform: 'translateZ(0)',
    backgroundColor: theme.palette.background.paper,
    padding: 10,
  },
  titleBar: {
    background:
      'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
      'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
  formControlContainer: {
    padding: 10,
    display: 'flex',
    justifyContent: 'space-evenly',
    border: `2px solid ${slateGrey}`,
  },
  formControl: {
    marginLeft: 25,
  },
  formControlCounter: {
    fontSize: 20,
    fontStyle: 'italic',
    fontWeight: 300,
    justifySelf: 'flex-end',
    width: '100px',
  },
  formLabel: {
    fontSize: 26,
    color: 'white !important',
    padding: 5,
    borderBottom: `2px solid ${slateGrey}`,
    background: slateGrey,
    textAlign: 'center',
    marginBottom: 20,
  },
  icon: {
    color: 'white',
  },
}))

const GreenCheckbox = withStyles({
  root: {
    color: slateGrey,
    marginRight: 35,
    '&$checked': {
      color: slateGrey,
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />)

export default function AdvancedGridList() {
  const { allContentfulGalleryData } = useStaticQuery(graphql`
    query GalleryDataQuery {
      allContentfulGalleryData {
        totalCount
        nodes {
          categories
          id
          featured
          createdAt
          contentful_id
          contentfulid
          image {
            file {
              url
            }
          }
        }
      }
    }
  `)

  const [queryData, setQueryData] = useState([])
  const classes = useStyles()
  const small = useMediaQuery('(max-width:1050px)')
  const [open, setOpen] = useState(false)
  const [filters, setFilters] = useState({
    walkways: false,
    patios: false,
    driveways: false,
    porches: false,
    pools: false,
  })
  const [loading, setLoading] = useState(false)
  const [viewImg, setViewImg] = useState(backgroundImg)
  const { walkways, patios, driveways, porches, pools } = filters

  const noFilterSelected =
    !walkways && !patios && !driveways && !porches && !pools

  useEffect(() => {
    setLoading(true)
    let filteredGallery = []

    if (noFilterSelected) {
      allContentfulGalleryData.nodes.forEach(img => {
        if (img.featured) {
          filteredGallery.push(img)
        }
      })
    }
    allContentfulGalleryData.nodes.forEach(img => {
      if (img.categories.includes('Walkways')) {
        if (walkways) {
          filteredGallery.push(img)
        }
      }
      if (img.categories.includes('Patios')) {
        if (patios) {
          filteredGallery.push(img)
        }
      }
      if (img.categories.includes('Driveways')) {
        if (driveways) {
          filteredGallery.push(img)
        }
      }
      if (img.categories.includes('Porches')) {
        if (porches) {
          filteredGallery.push(img)
        }
      }
      if (img.categories.includes('Pools')) {
        if (pools) {
          filteredGallery.push(img)
        }
      }
    })
    setQueryData(filteredGallery)
    setLoading(false)
  }, [filters])

  const handleChange = event => {
    setFilters({ ...filters, [event.target.name]: event.target.checked })
  }
  const onCloseModal = () => {
    setOpen(false)
  }
  const onViewImg = img => {
    setViewImg(img)
    setOpen(true)
  }

  const formLabels = [
    { name: 'pools', value: pools, label: 'Pool Decks' },
    { name: 'driveways', value: driveways, label: 'Driveways' },
    { name: 'porches', value: porches, label: 'Porches' },
    { name: 'walkways', value: walkways, label: 'Walkways' },
    { name: 'patios', value: patios, label: 'Patios' },
  ]

  return (
    <Layout backImg={backgroundImg} headerTitle="TAKING PRIDE IN EVERY PROJECT">
      <SEO title="Project Gallery" />
      <Grid container className={classes.root}>
        <Grid container item md={8}>
          <Grid item sm={12}>
            <h1 className={classes.title}>
              Explore Our{' '}
              <span
                style={{
                  color: slateGrey,
                  borderBottom: `1px solid ${slateGrey}`,
                  fontWeight: 400,
                }}
              >
                Featured Projects
              </span>
            </h1>
          </Grid>
          <Grid item sm={12}>
            <h3 className={classes.subtitle}>
              {' '}
              & follow us on{' '}
              <a href="https://www.instagram.com/4nbuild/">
                {' '}
                <span className={classes.subtitleLink}>
                  Instagram <InstaIcon />
                </span>
              </a>{' '}
              for most recent content
            </h3>
          </Grid>
        </Grid>
        <Grid item md={4} className={classes.formControlContainer}>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel className={classes.formLabel}>Filter Gallery</FormLabel>
            <FormGroup>
              {formLabels.map(el => (
                <FormControlLabel
                  control={
                    <GreenCheckbox
                      checked={el.value}
                      onChange={handleChange}
                      name={el.name}
                    />
                  }
                  label={el.label}
                />
              ))}
            </FormGroup>
          </FormControl>
          <h3 className={classes.formControlCounter}>
            {noFilterSelected
              ? `Featured ${queryData.length} photos`
              : `${queryData.length}/${allContentfulGalleryData.totalCount}`}
          </h3>
        </Grid>
      </Grid>

      <div className={classes.root}>
        {loading && <CircularProgress />}
        {!loading && (
          <GridList cellHeight={200} spacing={2} className={classes.gridList}>
            {queryData.map(tile => (
              <GridListTile key={tile.id} cols={small ? 2 : 1} rows={2}>
                <img src={tile.image.file.url} alt={tile.id} />
                <GridListTileBar
                  titlePosition="top"
                  actionPosition="left"
                  actionIcon={
                    <IconButton
                      onClick={() => onViewImg(tile.image.file.url)}
                      aria-label={`star ${tile.id}`}
                      className={classes.icon}
                    >
                      <LaunchIcon />
                    </IconButton>
                  }
                  className={classes.titleBar}
                />
              </GridListTile>
            ))}
          </GridList>
        )}
      </div>
      <Modal className={classes.modal} open={open} onClose={onCloseModal}>
        <ModalContent closeModal={onCloseModal} img={viewImg} />
      </Modal>
    </Layout>
  )
}
