import React from 'react'
import './button.css'

export default function ButtonHorizontal(props) {
  return (
    <div onClick={props.onClick} className="vertical" style={props.style || {}}>
      <span className="text">{props.title}</span>
    </div>
  )
}
