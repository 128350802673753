import React, { useState } from 'react'
import './header.css'
import { Link } from 'gatsby'
import Image from './image'
import { Grid, Tooltip } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Phone, Email } from '@material-ui/icons'
import Hidden from '@material-ui/core/Hidden'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import Drawer from '@material-ui/core/SwipeableDrawer'
import Typography from '@material-ui/core/Typography'
import Instagram from '../images/instagram.svg'
import {
  dirtyWhite,
  militaryGreen,
  lightGreen,
  darkGreen,
  slateGrey,
} from '../constants/colors'
import { phone, email } from '../constants/companyInfo'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    minHeight: 400,
    display: 'flex',
  },
  containerDiv: {
    width: '100%',
    height: '100%',
    background: 'rgba(0,0,0,0.3)',
  },
  leftNavContainer: {
    float: 'left',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  navIconContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  navIcon: { marginRight: 5, color: slateGrey },
  title: {
    fontSize: 18,
  },
  headerTitleText: {
    color: 'whitesmoke',
    fontWeight: 300,
    width: '100%',
    textAlign: 'center',
    fontFamily: 'Oswald',
  },
  imgGrid: {
    alignSelf: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 700,
  },
  mainImage: {
    width: '600px',
  },
  mainImageSm: {
    width: 300,
  },
  logoBox: {
    width: 150,
    background: `rgb(241,241,241)`,
    background: `radial-gradient(circle, rgba(241,241,241,1) 39%, rgba(196,203,209,0) 80%)`,
  },
  drawer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  drawerIconContainer: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    background: `rgba(196, 203, 209, 0.73)`,
    padding: 5,
  },
  drawerIcon: { marginLeft: 15, marginRight: 5, color: slateGrey },
  drawerLinkList: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    listStyleType: 'none',
    margin: 0,
  },
  drawerLogoBox: { width: 200, alignSelf: 'center', margin: 15 },
  drawerLink: {
    marginTop: 20,
    marginBottom: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  contactSpan: {
    marginLeft: 15,
    display: 'flex',
    alignItems: 'center',
    letterSpacing: 0.3,
  },
  instaLinkHeader: {
    width: 32,
    height: 32,
    borderRadius: 10,
    backgroundColor: '#424242',
    padding: 2,
    marginLeft: 15,
    '&:hover': {
      opacity: 0.72,
    },
  },
  drawerInstaLink: {
    width: 32,
    height: 32,
    borderRadius: 10,
    backgroundColor: '#424242',
    '&:hover': {
      opacity: 0.72,
    },
  },
}))
const links = [
  { route: '/', title: 'Home' },
  { route: '/gallery', title: 'Gallery' },
  { route: '/about', title: 'About' },
  { route: '/contact', title: 'Contact' },
]
const Header = ({ headerTitle, backImg }) => {
  const classes = useStyles()
  const [menuOpen, setMenuOpen] = useState(false)

  const toggleMenu = () => {
    setMenuOpen(!menuOpen)
  }
  return (
    <div
      style={{ backgroundImage: `url(${backImg})` }}
      className={classes.root}
    >
      <div className={classes.containerDiv}>
        <nav
          style={{ background: `rgba(196, 203, 209, 0.73)` }}
          className="stroke"
        >
          <div className={classes.leftNavContainer}>
            <div className={classes.logoBox}>
              <Image />
            </div>
            <Hidden smDown>
              <div className={classes.navIconContainer}>
                <span className={classes.contactSpan}>
                  <Phone size="small" className={classes.navIcon} />
                  {phone}
                </span>
                <span className={classes.contactSpan}>
                  <Email size="small" className={classes.navIcon} />
                  {email}
                </span>
              </div>
              <div></div>
            </Hidden>
          </div>
          <Hidden smDown>
            <ul>
              {links.map(el => (
                <li>
                  <Link activeClassName="active" className="link" to={el.route}>
                    {el.title}
                  </Link>
                </li>
              ))}
              <Tooltip title="Instagram">
                <li style={{ marginRight: 10 }}>
                  <Link to="https://www.instagram.com/4nbuild/">
                    {' '}
                    <img src={Instagram} className={classes.instaLinkHeader} />
                  </Link>
                </li>
              </Tooltip>
            </ul>
          </Hidden>
          <Hidden mdUp>
            <IconButton style={{ margin: 5 }} onClick={() => toggleMenu()}>
              <MenuIcon style={{ color: slateGrey }} />
            </IconButton>
            <Drawer
              anchor="top"
              open={menuOpen}
              onClose={() => setMenuOpen(false)}
              className={classes.drawer}
            >
              <ul className={classes.drawerLinkList}>
                <li className={classes.drawerLink}>
                  <Link
                    to="https://www.instagram.com/4nbuild/"
                    className="link"
                  >
                    {' '}
                    <img src={Instagram} className={classes.drawerInstaLink} />
                  </Link>
                </li>
                {links.map(el => (
                  <li className={classes.drawerLink}>
                    <Link
                      activeClassName="active"
                      className="link"
                      to={el.route}
                    >
                      {el.title}
                    </Link>
                  </li>
                ))}
              </ul>
              <div className={classes.drawerLogoBox}>
                <Image />
              </div>
              <div className={classes.drawerIconContainer}>
                <span>
                  <Phone size="small" className={classes.drawerIcon} />
                </span>{' '}
                {phone}
                <span>
                  <Email size="small" className={classes.drawerIcon} />
                </span>{' '}
                {email}
              </div>
            </Drawer>
          </Hidden>
        </nav>
        <Grid className={classes.imgGrid} container>
          <Grid item sm={12}>
            <Typography
              gutterBottom
              variant="h2"
              component="h2"
              className="header-title"
              className={classes.headerTitleText}
            >
              {headerTitle}
            </Typography>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}
export default Header
